/* Custom form style */

/* range slider style */
.range-slider {
  .irs-handle {
    cursor: pointer;
  }

  .irs-from,
  .irs-to {
    @include font-size(0.75rem);
    font-weight: 500;
  }

  //primary skin
  &.primary-skin {
    .irs-handle {
      background-color: $primary;
      box-shadow: 0 0 0 8px rgba($primary, 0.15);
      border: 0;

      &:hover,
      &:focus {
        background-color: $primary;
      }
    }

    .irs-bar {
      background-color: $primary;
    }

    .irs-line {
      background-color: rgba($primary, 0.15);
    }

    .irs-from,
    .irs-to,
    .irs-single {
      background-color: $primary;

      &:before {
        border-top-color: $primary;
      }
    }
  }

  //Secondary skin
  &.secondary-skin {
    .irs-handle {
      background-color: $secondary;
      box-shadow: 0 0 0 8px rgba($secondary, 0.15);
      border: 0;

      &:hover,
      &:focus {
        background-color: $secondary;
      }
    }

    .irs-bar {
      background-color: $secondary;
    }

    .irs-line {
      background-color: rgba($secondary, 0.15);
    }

    .irs-from,
    .irs-to,
    .irs-single {
      background-color: $secondary;

      &:before {
        border-top-color: $secondary;
      }
    }
  }

  //round style
  .irs--round {
    .irs-handle {
      height: 10px;
      width: 10px;
      top: 32.5px;
    }

    .irs-from,
    .irs-to,
    .irs-single {
      border-radius: 3px;
    }
  }
}

/*Custom Select Style*/
.custom_select {
  position: relative;
  display: block;
  overflow: hidden;

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    padding-right: 46px;

    &:focus {
      outline: none;
    }
  }

  .select_indicator {
    position: absolute;
    display: inline-flex;
    align-items: center;
    right: 10px;
    height: 20px;
    width: 20px;
    top: calc(50% - 10px);
    transform-origin: center;
    transition: 0.2s transform;
    pointer-events: none;
    background: escape-svg($select-arrow) no-repeat center;
    &:before {
      height: 28px;
      width: 1px;
      background-color: $border-color;
      content: "";
      position: absolute;
      left: -8px;
    }
  }

  &.in:after {
    transform: rotate(-180deg);
  }
}

/*custom  check box ~// modified to bottstrap custom check box*/
.custom-checkbox {
  &.custom-control {
    padding-left: 0;
    min-height: auto;
  }

  .custom-control-label {
    line-height: 1.125rem;
    padding-left: 26px;

    &:before {
      background: transparent;
      border: 2px solid darken($body-bg, 15%);
      transition: all 0.3s ease;
      border-radius: 3px;
      content: "";
      text-align: center;
      line-height: 0.875rem;
      font-family: "FontAwesome";
      @include font-size(0.6375rem);
      top: 0;
      left: 0;
    }

    &:after {
      display: none;
    }
  }

  .custom-control-input {
    &:checked ~ .custom-control-label:before {
      background-color: $primary;
      border-color: $primary;
      content: "\f00c";
      box-shadow: 0px 0px 15px rgba($primary, 0.5);
    }
  }
}

/*Custom Increment Decrement Style*/
.increment_decrement {
  position: relative;

  input {
    padding-right: 55px;
    appearance: none;
    -webkit-appearance: none;

    &[readonly] {
      background-color: $input-bg;
    }
  }

  .handler {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);

    span {
      height: 20px;
      width: 20px;
      border: $input-border-width solid $input-border-color;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      line-height: 1;
      @include font-size(0.625rem);
      transition: all 0.3s ease;
      cursor: pointer;

      &:hover,
      &:focus {
        box-shadow: 0px 0px 15px rgba($primary, 0.5);
        background-color: $primary;
        border-color: $primary;
        color: $white;
      }

      & + span {
        margin-left: 5px;
      }
    }
  }
}

/*Custom Date Range Picker Style*/
.react-datepicker__input-container {
  position: relative;
  display: block;

  &:after {
    font-family: "bootstrap-icons";
    position: absolute;
    top: 50%;
    display: inline-block;
    transform: translateY(-50%);
    content: "\f214";
    right: 10px;
    @include font-size(1.125rem);
    color: $primary;
    pointer-events: none;
  }
}

.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
  background-color: rgba($primary, 0.3);
}

.daterangepicker td.in-range {
  background-color: rgba($primary, 0.1);
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: $primary;
}

//@include switch-input
.switch-input {
  overflow: hidden;
  .check-input {
    opacity: 0;
    height: 0;
    width: 0;
  }
  .check-label {
    position: relative;
    min-height: 24px;
    font-size: 0.8375rem;
    font-weight: 500;
    line-height: 24px;
    &:before,
    &:after {
      position: absolute;
      content: "";
      border-radius: 50rem;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
    &:before {
      height: 20px;
      border: 2px solid darken($border-color, 7%);
      width: 40px;
    }

    &:after {
      height: 22px;
      width: 22px;
      background-color: darken($border-color, 7%);
    }
  }

  &:not(.align-right) .check-label {
    padding-left: 50px;
    &:before,
    &:after {
      left: 0;
      transition: background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, left 0.15s ease-in-out;
    }
  }

  &.align-right .check-label {
    padding-right: 50px;
    &:before,
    &:after {
      right: 0;
      transition: background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, right 0.15s ease-in-out;
    }
  }

  .check-input {
    &:checked ~ .check-label {
      &:before {
        border-color: $success;
      }
      &:after {
        left: 18px;
        background-color: $success;
      }
    }
  }

  &.align-right {
    .check-label:after {
      right: 18px;
    }
    .check-input {
      &:checked ~ .check-label {
        &:after {
          right: 0;
          left: auto;
        }
      }
    }
  }
}

.custom-form {
  .form-label {
    color: $headings-color;
    font-weight: 500;
    letter-spacing: 0.2px;
  }
  .form-control {
    min-height: 48px;
    border: 2px solid $border-color;
    box-shadow: none;
    outline: 0;
    color: $headings-color;
    &:focus {
      border-color: rgba($primary, 0.5);
    }
  }
  .switch-input {
    .check-label {
      min-height: 48px;
      line-height: 48px;
      padding-left: 90px;
      color: darken($body-color, 10%);
      @include font-size(0.9375rem);
      letter-spacing: 0.2px;
      span {
        display: inline-block;
        &:before {
          content: attr(data-label);
        }
      }
      &:before {
        height: 30px;
        width: 80px;
      }
      &:after {
        height: 32px;
        width: 32px;
        font-family: "bootstrap-icons";
        content: "\f5ee";
        text-align: center;
        line-height: 32px;
        @include font-size(1.5rem);
        background-color: $danger;
        color: $white;
      }
    }
    .check-input {
      &:checked ~ .check-label {
        span {
          &:before {
            content: attr(data-checked-label);
          }
        }
        &:after {
          left: 48px;
          content: "\f26e";
        }
      }
    }
  }

  .is-invalid ~ .invalid-feedback {
    display: block;
  }

  //recat multi select
  .css-2b097c-container {
    > div:nth-child(2) {
      min-height: 48px;
      border: 2px solid $border-color;
      box-shadow: none;
      outline: 0;
    }
  }
  .css-g1d714-ValueContainer {
    padding-left: 15px;
    padding-right: 15px;
  }
  .css-1okebmr-indicatorSeparator {
    background-color: $border-color;
  }
  .css-tj5bde-Svg {
    color: $primary;
  }

  .select__indicator {
    > svg {
      color: $primary;
    }
    &.select__clear-indicator {
      > svg {
        color: $danger;
      }
    }
  }
}

//switch-group
.switch-group {
  display: flex;
  align-items: center;
  margin: $grid-gutter-width/-2;
  flex-wrap: wrap;
  > * {
    padding: $grid-gutter-width/2;
  }
}

.custom-file-input {
  //color: transparent;
  display: inline-block;
  //width: 160px;
  margin-right: 5px;
  padding: 0;
  border: 0;
  &:hover,
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
  // &::-webkit-file-upload-button {
  //   visibility: hidden;
  // }

  &:before {
    content: "browse";
    color: $white;
    display: inline-block;
    background: $secondary;
    border-radius: 0;
    text-transform: uppercase;
    width: 100%;
    line-height: 46px;
    text-align: center;
    outline: none;
    border: 0;
    white-space: nowrap;
    //-webkit-user-select: none;
    cursor: pointer;
    font-weight: 500;
    letter-spacing: 1px;
    @include font-size(0.875rem);
  }

  &:hover,
  &:focus {
    background-color: darken($primary, 5%);
    border: 0;
    outline: none;
  }

  &:active:before {
    background-color: darken($primary, 5%);
  }
}

.banner-image {
  .inline {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  .image-area {
    max-width: 230px;
    border: 2px solid $border-color;
    height: 130px;
    padding: 5px;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    &:before {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      content: "\f40a";
      font-family: "bootstrap-icons";
      font-size: 5rem;
      display: flex;
      align-items: center;
      line-height: 1;
      justify-content: center;
      opacity: 0.15;
      color: $primary;
      z-index: -1;
    }
  }

  .btn-delate {
    background: $danger;
    color: $white;
    padding: 2px 16px;
    @include font-size(0.75rem);
    border-radius: 50rem;
    cursor: pointer;
  }
  .image-holder{
    position: relative;
    height: 66px;
    padding: 8px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    img{
      height: 48px;
      width: 48px;
      max-width: 100%;
    }
  }
}

//ql-container
.quill {
  .ql-container {
    position: relative;
    min-height: 412px;
    .ql-editor {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
    }
  }
  &.small {
    .ql-container {
      min-height: 250px;
    }
  }
}

//input-tag
.input-tag {
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;
  ul {
    padding: 0;
    margin: 0;
    display: inline-flex;
    flex-wrap: wrap;
    > li {
      list-style: none;
      background-color: hsl(0, 0%, 90%);
      color: $headings-color;
      padding: 3px 3px 3px 6px;
      line-height: 1.45;
      border-radius: 2px;
      display: inline-flex;
      margin: 2.5px;
      span {
        align-items: center;
        border-radius: 3px;
        display: flex;
        padding-left: 4px;
        padding-right: 4px;
        box-sizing: border-box;
        color: $primary;
        @include font-size(1.125rem);
        cursor: pointer;
        &:before {
          font-weight: bold !important;
        }
      }
    }
  }
  input {
    border: none;
    box-shadow: none;
    width: auto;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

//custom-alert
.custom-alert {
  @include font-size(0.8375rem);
  border: 0;
  margin-top: 1.25rem;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .btn-close {
    @include font-size(0.8375rem);
    padding: 0.75rem;
    opacity: 0.6;
    outline: none;
    box-shadow: none;
  }
  .anticon.anticon-close{
    color: darken($danger, 10%);
  }
  .ant-alert-message{
    text-transform: uppercase;
    font-weight: 500;
    color: darken($danger, 10%);
    margin-bottom: 0;
  }
  &.fadeout{
    display: none;
  }
}

.custom-form {
  //ant switch
  .ant-switch {
    background-color: transparent;
    border: none;
    box-shadow: none;
    outline: none;
    position: static;
    display: flex;
    align-items: center;
    background-image: none;
    .ant-click-animating-node {
      animation: none;
    }
    .ant-switch-handle {
      height: 30px;
      width: 80px;
      border-radius: 50rem;
      box-shadow: none;
      border: 2px solid darken($border-color, 7%);
      position: relative;
      top: auto;
      left: auto;
      &:before {
        height: 32px;
        width: 32px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-color: $danger;
        border-radius: 50rem;
        font-family: "bootstrap-icons";
        content: "";
        color: $white;
        line-height: 32px;
        @include font-size(1.5rem);
      }
    }

    .ant-switch-inner {
      color: $body-color;
      margin: 0 0 0 10px;
      font-weight: 500;
      @include font-size(0.9375rem);
      letter-spacing: 0.2px;
      white-space: nowrap;
    }

    &:not(.ant-switch-checked) {
      .ant-switch-handle {
        &:before {
          left: 0;
        }
      }
    }

    &.ant-switch-checked {
      border-color: $success;
      .ant-switch-handle {
        left: auto;
        &:before {
          background-color: $success;
          content: "";
          left: calc(100% - 30px);
        }
      }
    }
  }

  //.ant-form-item-label > label
  .ant-form-item-label > label {
    color: #263238;
    font-weight: 500;
    letter-spacing: 0.2px;
  }

  &.ant-form-vertical .ant-form-item-label {
    padding-bottom: 0;
  }
  .ant-picker {
    width: 100%;
  }
  .ant-picker,
  .ant-input,
  .ant-input-number,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-input-number-group-addon
    .ant-select.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    @include font-size(0.875rem);
    border-color: $border-color;
    min-height: 42px;
  }
  .ant-picker-large .ant-picker-input > input {
    @include font-size(0.875rem);
    border-color: $border-color;
  }
  .ant-input-number-group-addon {
    border: 0;
  }
  .ant-input-number-group-addon
    .ant-select.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    border-left: 0;
  }
  .ant-input-number-group-wrapper {
    display: block;
  }
  .ant-input-number {
    width: 100%;
  }

  &.ant-form-large {
    .ant-input {
      min-height: 42px;
    }
  }

  .ant-select-multiple.ant-select-lg .ant-select-selection-item {
    height: 26px;
    border: 0;
    line-height: 26px;
    background-color: $body-bg;
  }

  .ant-select-multiple .ant-select-selection-item-content {
    @include font-size(0.75rem);
    color: darken($primary, 30%);
  }

  .ant-tabs-tabpane{
  }
}

//trip_facts
.trip_facts {
  .ant-row {
    position: relative;
    .btn-delate {
      position: absolute;
      right: 0;
      top: 0;
      color: $danger;
      z-index: 1;
      cursor: pointer;
    }
  }
}

//depature-list

.departures,
.group-price,
.itineraries {
  .btn-delate {
    background-color: $danger;
    color: $white;
    padding: 3px 12px;
    border-radius: 50rem;
    font-weight: normal;
    @include font-size(0.75rem);
    margin-left: 5px;
    cursor: pointer;
  }
}
.depature-list {
  display: flex;
  margin-left: -7.5px;
  margin-right: -7.5px;
  align-items: center;
  flex-wrap: wrap;
  > * {
    padding-left: 7.5px;
    padding-right: 7.5px;
    max-width: calc(100% / 4);
    flex: 0 0 calc(100% / 4);
    min-width: 200px;
  }
  @include media-breakpoint-down(lg) {
    > * {
      width: 100%;
      max-width: 100%;
      flex: 0 0 100%;
    }
  }
}


.ant-table-row-expand-icon{
  border-radius: 50rem;
  background-color: rgba($primary, .25);
  color: $primary;
  border: 1px solid rgba($primary, .75);
  //box-shadow: 0 0 5px rgba($primary, 0.15);
  &:hover,
  &:active,
  &:focus,
  &:focus-within{
    background-color: $primary;
    color: $white;
    outline: none;
    border-color: transparent;
  }
}

.ant-message-custom-content{
  display: flex;
  align-items: center;
  @include font-size(.75rem);
}

.mce-content-body{
  border: 1px solid $border-color;
  padding: $input-padding-y $input-padding-x;
  resize:vertical;
  min-height: 200px;
  overflow: hidden;
  outline: none;
}

.upload-list-inline{
  display: flex;
  flex-direction: column-reverse;
  .ant-upload-list{
    margin-bottom: 10px;
  }
}

.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}
.upload-list-inline .ant-upload-animate-enter {
  animation-name: uploadAnimateInlineIn;
}
.upload-list-inline .ant-upload-animate-leave {
  animation-name: uploadAnimateInlineOut;
}


.mce-content-body:not([dir=rtl])[data-mce-placeholder]:not(.mce-visualblocks)::before{
  left: $input-padding-x !important;
  top: $input-padding-y;
}

.custom-form {
  .tox-tinymce{
    border-color: $border-color;
  }
  .tox .tox-menubar + .tox-toolbar, .tox .tox-menubar + .tox-toolbar-overlord .tox-toolbar__primary{
    
    border-color: $border-color;

  }
  .tox:not([dir="rtl"]) .tox-toolbar__group:not(:last-of-type){
    
    border-color: $border-color;
  }
  .tox .tox-toolbar, .tox .tox-toolbar__overflow, .tox .tox-toolbar__primary{
    background: url("data:image/svg+xml;charset=utf8,%3Csvg height='39px' viewBox='0 0 40 39px' width='40' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0' y='38px' width='100' height='1' fill='%23dbe7f1'/%3E%3C/svg%3E") left 0px top 0px rgb(255, 255, 255);
  }
}

.custom-form{
  .noStar{
    .ant-form-item-label>.ant-form-item-required:before{
      content: '';
    }
  }
  .ant-rate-star.ant-rate-star-zero{
    color: darken($body-bg, 10%);
  }
}

.custom-form{
  .ant-input-affix-wrapper-lg{
    padding-top: 0;
    padding-bottom: 0;
    border-color: $border-color;
  }
}

.step-content{
  &:not(.active){
    display: none;
  }
}

.avatar{
  height: 104px;
  width: 104px;
  border: 1px dashed darken($border-color, 5%);
  background-color: rgba($border-color, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 2px;
  &:hover{
    border-color: rgba($primary, 0.8);
    cursor: pointer;
  }
  &+input{
    display: none;
  }

}