/* data Table  Style*/

.data-table {
  display: block;
  overflow: hidden;
  width: 100%;
  @include font-size(.9375rem);

  .hdddnp{
    background-color: transparent;
    padding: 0;
    padding-bottom: $grid-gutter-width/2;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .sc-jcFkyM{
      height: 38px;
      background-color: $white;
      border: 2px solid $border-color;
      color: $body-color;
      padding: 4px 10px;
      outline: none;
      box-shadow: none;
      @include font-size(0.875rem);
      border-radius: 3px;
    }
    .sc-caiKgP{
      border: none medium;
      outline: 0;
      border-radius: 0;
      position: relative;
      &:before{
        content: "\f5ee";
        font-family: 'bootstrap-icons';
      }
    }
  }

  .hokyBq{
    @include font-size(.9375rem);
    min-height: 54px;
  }

  .fJOITo{
    padding-top: 10px;
  }

  .input-search{
    display: flex;
    align-items: center;
    position: relative;
    label{
      font-size: 0.8375rem;
      font-weight: 500;
      padding: 0 5px;
    }
    input{
      height: 38px;
      outline: none;
      box-shadow: none;
      border: 1px solid darken($border-color, 5%);
      border-radius: 2px;
      min-width: 250px;
      font-family: $font-family-base;
    }
    &:after{
      content: "\f502";
      font-family: 'bootstrap-icons';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: $grid-gutter-width/2;
    }
  }

  .link{
    color: #5f728a;
    text-decoration: none;
    font-weight: 500;
    &:hover{
      color: $primary;
    }
  }

  .jMdbjj{
    background-color: transparent;
    padding: 0;
  }

  .dataTable-header {
    display: flex;
    align-items: center;
    margin: -10px;
    > * {
      padding: 10px;
      &:last-child {
        margin-left: auto;
      }
    }

    input,
    select {
      height: 38px;
      background-color: $white;
      border: 2px solid $border-color;
      color: $body-color;
      padding: 4px 10px;
      outline: none;
      box-shadow: none;
      @include font-size(0.875rem);
      border-radius: 3px;
    }

    label {
      @include font-size(0.8375rem);
      font-weight: 500;
      padding: 0 5px;
      input {
        margin-left: 5px;
      }
    }
  }

  .category-sorting {
    display: flex;
    align-items: center;
    .custom_select{      
      margin-left: 10px;
    }
    .form-control{
      height: 38px;
      outline: none;
      box-shadow: none;
      border: 1px solid darken($border-color, 5%);
      border-radius: 2px;
      min-width: 200px;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .table-responsive {
    background-color: $white;
    padding: $grid-gutter-width;
    width: 100%;
    margin: 20px 0;
  }

  .table > :not(:last-child) > :last-child > * {
    border-bottom-color: $border-color;
    border-bottom-width: 2px;
  }
  .rdt_TableHead .rdt_TableHeadRow {
    >div {
      font-weight: 600;
      color: $headings-color;
      @include font-size(1rem);
    }
  }

  .bVSUzA{
    border-bottom-color: $border-color;
    border-bottom-width: 2px;
  }

  .hokyBq:not(:last-of-type){
    border-bottom-color: $border-color;
  }

  .rdt_TableBody{
    .rdt_TableRow {
      &.child{
        .link{
          &:before{
            content: "--";
            display: inline-block;
            vertical-align: middle;
            margin-right: 5px;
          }
        }

      }
    }
  }

  .table {
   
    tbody {
      tr {
        td {
          padding-top: 15px;
          padding-bottom: 15px;
          vertical-align: middle;
          > a {
            color: darken($body-color, 10%);
            text-decoration: none;
            font-weight: 500;
            &:hover {
              color: $primary;
            }
          }
        }
      }
    }
  }

  *:not(.status) .form-check {
    .form-check-input {
      height: 1.25rem;
      width: 1.25rem;
      border: 2px solid $border-color;
      background-color: transparent;
      outline: none;
      box-shadow: none;
      &:checked {
        background-color: $primary;
        border-color: $primary;
        box-shadow: 0px 0px 6px rgba($primary, 0.15);
      }
    }
  }

  //bi-arrows-expand
  .bi-arrows-expand {
    cursor: move;
    @include font-size(1.25rem);
    color: rgba($headings-color, 0.9);
  }

  .status {
    .form-check {
      input {
        display: none;
      }
      label {
        display: inline-block;
        position: relative;
        width: 22px;
        line-height: 23px;
        margin: 0;
        height: 22px;
        border-radius: 50%;
        background: $danger;
        box-shadow: 0px 0px 6px rgba($danger, 0.15);
        transition: all 0.3s ease;
        cursor: pointer;

        &:before {
          content: "\f5ee";
          font-family: "bootstrap-icons";
          @include font-size(0.8375rem);
          color: $white;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          font-weight: bold;
        }
      }
      input:checked + label {
        background-color: $success;
        box-shadow: 0px 0px 6px rgba($success, 0.15);
        &:before {
          content: "\f272";
        }
      }
    }
  }

  .dataTable-footer {
    display: flex;
    align-items: center;
    margin: -10px;
    > * {
      padding: 10px;
      &:last-child {
        margin-left: auto;
      }
    }
    .dataTables_paginate{
        display: flex;
        margin: -5px -10px;
        align-items: center;
        a{
            cursor: pointer;
        }
        >*{
            padding: 5px 10px;
        }
        span{
            a{
                height: 36px;
                width: 36px;
                border-radius: 50rem;
                border: 2px solid $border-color;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                background-color: $white;
                text-decoration: none;
                font-weight: 500;
                color: $body-color;
                cursor: pointer;
                outline: none;
                &+a{
                    margin-left: 5px;
                }
                &.current{
                    background-color: $primary;
                    color: $white;
                }
            }
        }
    }
  }

  //ui-sortable-handle
  .ui-sortable-handle{
      width: 100%;
  }

  .ant-table{
    //color: darken($body-color, 5%);
    font-family: $font-family-base;
  }


  .ant-table-thead > tr > th{
    background-color: transparent;
    border-bottom: 2px solid $border-color;
    color: $headings-color;
    font-weight: 600;
  }
  .ant-table-tbody > tr > td{
    border-color: $border-color;

  }
  .ant-table-tbody > tr > td.ant-table-cell-row-hover{
    background-color: $body-bg;
  }
  .ant-table-container table > thead > tr:first-child th:first-child{
    width: 3%;
  }
  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
    background-color: $border-color;
  }
  
}

.row-dragging {
	background: $white;
	border: 1px solid $border-color;
  }
  
  .row-dragging td {
	padding: 16px;
  }
  
  .row-dragging .drag-visible {
	visibility: visible;
  }

.sorting-wrapper{
  display: flex;
  align-items: center;
  padding-bottom: $grid-gutter-width/2;
  flex-wrap: wrap;
  margin: -5px $grid-gutter-width/-2;
  >*{
    padding: 5px $grid-gutter-width/2;
  }
  >*:nth-child(2){
    @include media-breakpoint-up(lg){
      margin-left: auto;

    }
  }

  .alert-text{
    line-height: 22px;
    i{
      vertical-align: text-bottom;
      line-height: 1;
      display: inline-block;
      margin-right: 6px;
      @include font-size(1.25rem);
    }
  }
}